

































// Core
import { Component, Prop, Mixins } from 'vue-property-decorator'

// Mixins
import Documents from '@/mixins/Documents'

// Services
import http from '@/http'

// Interfaces
import { IDocument } from '@/interfaces/Interface'

@Component({
  name: 'Document',
})
export default class DocumentComponent extends Mixins(Documents) {
  @Prop({ default: () => ({}) })
  private doc!: IDocument

  @Prop({ default: () => false })
  private disabledComment!: boolean

  @Prop({ default: () => false })
  private allowDelete!: boolean

  private showFormComment: boolean = false

  private closeFormComment() {
    this.showFormComment = false
  }

  private addComment(document) {
    http
      .put(`/documents/${this.doc.id}`, {
        comment: document.comment,
      })
      .then(() => {
        this.showFormComment = false
      })
  }

  private download(doc, event) {
    const target = event.currentTarget
    target.classList.add('button_expectation')
    target.disabled = true
    this.downloadDocument(doc).then(() => {
      target.disabled = false
      target.blur()
      target.classList.remove('button_expectation')
    })
  }

  private deleteDocument() {
    http.delete(`/documents/${this.doc.id}`).then(() => {
      this.$emit('deleteDocument')
    })
  }
}
